import dayjs from "dayjs";

export const htmlToPlainText = html => {
  if (!html) {
    return "";
  }

  return html.replace(/<[^>]*>/g, "").replace(/{[^}]*}/g, "");
};

export const sanitiseHtml = html => {
  if (!html) {
    return "";
  }

  return html
    .replace(/{{[^}]*}}/g, "")
    .replace(/<span[^>]*>/g, "")
    .replace(/<\/span>/g, "");
};

export const getSlug = slug => {
  const parts = slug.split("/");

  return parts[parts.length - 1];
};

export const formatDate = date => {
  if (!date) {
    return;
  }

  return dayjs(date).format("DD MMM YYYY");
};

export const isValidEmail = email => {
  return email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};
