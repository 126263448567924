import React from "react";

import { base, logoContainer } from "./SocialsBanner.module.scss";

import Link from "../components/Link";

import { combine } from "../utils/classNames";

const SocialsBanner = props => {
  const { className } = props;

  return (
    <section className={combine(base, className)}>
      <img src="/images/socials.svg" alt="" />
      <div>
        <h2>Let's get social</h2>
        <div className={logoContainer}>
          <Link href="https://www.facebook.com/brandcrushplatform/" external>
            <img src="/images/facebook-logo.svg" alt="Facebook logo" />
          </Link>
          <Link href="https://www.instagram.com/brandcrush.platform/" external>
            <img src="/images/instagram-logo.svg" alt="Instagram logo" />
          </Link>
          <Link href="https://www.linkedin.com/company/brandcrush/" external>
            <img src="/images/linkedin-logo.svg" alt="Linkedin logo" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SocialsBanner;
