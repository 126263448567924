import React from "react";

import { featured, recent, all, socialsBanner } from "./blog.module.scss";

import Layout from "../layouts/Layout";
import Button from "../components/Button";
import BlogBanner from "../partials/BlogBanner";
import FeaturedBlog from "../partials/FeaturedBlog";
import BlogCard from "../partials/BlogCard";
import SocialsBanner from "../partials/SocialsBanner";

import { useClientPagination, useMediaQuery } from "../utils/hooks";

const BlogPage = props => {
  const {
    pageContext: { blogs, featuredBlog },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  const NUMBER_BLOGS = 9;

  const recentBlogs = blogs.slice(0, 6);
  const allBlogs = blogs.slice(6);

  const [results, showMore, shouldShowMore] = useClientPagination(NUMBER_BLOGS, allBlogs);

  return (
    <Layout
      pageTitle="Blog | Brandcrush"
      ogImage="/images/seo/blog.jpg"
      ogDescription="Our latest news, views and opinions. Discover industry perspectives, how-to guides and updates on all things Brandcrush."
    >
      <BlogBanner />
      <section className={featured}>
        <FeaturedBlog
          imageSrc={featuredBlog.featured_image}
          imageAlt={featuredBlog.featured_image_alt_text}
          heading={featuredBlog.name}
          body={featuredBlog.post_summary}
          slug={featuredBlog.slug}
        />
      </section>
      <section className={recent}>
        {recentBlogs.map((blog, index) => (
          <BlogCard
            key={index}
            imageSrc={blog.featured_image}
            imageAlt={blog.featured_image_alt_text}
            heading={blog.name}
            body={blog.post_summary}
            slug={blog.slug}
          />
        ))}
      </section>
      <section className={all}>
        <div>
          {results.slice(0, isMobile ? results.length - 1 : results.length).map((blog, index) => (
            <BlogCard
              key={index}
              imageSrc={blog.featured_image}
              imageAlt={blog.featured_image_alt_text}
              heading={blog.name}
              body={blog.post_summary}
              slug={blog.slug}
              size="sm"
            />
          ))}
        </div>
        {shouldShowMore && (
          <div>
            <Button isSecondary color="navy" onClick={showMore}>
              Load more
            </Button>
          </div>
        )}
      </section>
      <SocialsBanner className={socialsBanner} />
    </Layout>
  );
};

export default BlogPage;
