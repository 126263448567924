import React from "react";
import { navigate } from "gatsby";

import { base, blogImage, extract } from "./FeaturedBlog.module.scss";

import { combine } from "../utils/classNames";
import { htmlToPlainText, getSlug } from "../utils/misc";

const FeaturedBlog = props => {
  const { className, imageSrc, imageAlt, heading, body, slug } = props;

  const handleClick = () => {
    navigate(`/blog/${getSlug(slug)}`);
  };

  return (
    <div className={combine(base, className)} onClick={handleClick}>
      <img className={blogImage} src={imageSrc} alt={imageAlt} />
      <div className={extract}>
        <h4>Featured</h4>
        <h2>{heading}</h2>
        <p dangerouslySetInnerHTML={{ __html: htmlToPlainText(body) }}></p>
      </div>
    </div>
  );
};

export default FeaturedBlog;
