import React, { useRef, useEffect } from "react";
import { navigate } from "gatsby";

import { base, image, extract } from "./BlogCard.module.scss";

import { combine, getClassNameModifiers } from "../utils/classNames";
import { htmlToPlainText, getSlug } from "../utils/misc";

const BlogCard = props => {
  const { className, imageSrc, imageAlt, heading, body, slug } = props;

  const headingEl = useRef(null);
  const paragraphEl = useRef(null);

  useEffect(() => {
    const lineHeight = parseInt(getComputedStyle(headingEl.current).lineHeight.replace("px", ""));
    const headingHeight = parseInt(headingEl.current.offsetHeight);

    if (headingHeight > 3 * lineHeight) {
      paragraphEl.current.style["-webkit-line-clamp"] = 2;
    } else if (headingHeight > 2 * lineHeight) {
      paragraphEl.current.style["-webkit-line-clamp"] = 3;
    } else if (headingHeight > lineHeight) {
      paragraphEl.current.style["-webkit-line-clamp"] = 4;
    } else {
      paragraphEl.current.style["-webkit-line-clamp"] = 5;
    }
  });

  const modifiers = getClassNameModifiers(props, ["size"]);

  const handleClick = () => {
    navigate(`/blog/${getSlug(slug)}`);
  };

  return (
    <div className={combine(base, modifiers, className)} onClick={handleClick}>
      <img className={combine(image, modifiers)} src={imageSrc} alt={imageAlt} />
      <div className={combine(extract, modifiers)}>
        <h3 ref={headingEl}>{heading}</h3>
        <p ref={paragraphEl} dangerouslySetInnerHTML={{ __html: htmlToPlainText(body) }}></p>
      </div>
    </div>
  );
};

export default BlogCard;
