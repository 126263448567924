import React from "react";

import { base } from "./BlogBanner.module.scss";

const BlogBanner = props => {
  return (
    <section className={base}>
      <div>
        <h1>News &amp; views</h1>
        <p>Stay up to date with the latest Brandcrush news, developments and industry insights.</p>
      </div>
      <img src="/images/blog-banner.svg" alt="" />
    </section>
  );
};

export default BlogBanner;
